import { IQuestion } from '../../../../server/src/interfaces';
import {IAnswer} from '../../types/IAnswer';

interface TextQuestionProps {
    question: IQuestion;
    answer: IAnswer;
    handleAnswer: (answer: IAnswer) => void;
}

export const TextQuestion = ({ question, answer, handleAnswer }: TextQuestionProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleAnswer({ questionId: question.id, label: question.label, value: e.target.value, type: question.type });
    };

    const inputType = question.type === 'textarea' ? 'textarea' : 'input';

    return (
        <div className="mb-4">
            <div dangerouslySetInnerHTML={{__html: question.label}}/>
            {question.type === 'textarea' ? (
                <textarea
                    id={question.id}
                    required={question.required}
                    onChange={handleChange}
                    rows={4}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 shadow-sm transition duration-150 ease-in-out"
                ></textarea>
            ) : (
                <input
                    id={question.id}
                    type={question.type}
                    required={question.required}
                    onChange={handleChange}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 shadow-sm transition duration-150 ease-in-out"
                />
            )}
            {question.hint && <div className="text-xs" dangerouslySetInnerHTML={{__html: question.hint}} />}
        </div>
    );
};