import React from 'react';
import {Link} from "react-router-dom";
import getCompanyData from "../Utils/CompanyLocator";

interface SuccessProps {
    password: string;
}

const Success = ({password} : SuccessProps) => {

    const handlePrint = () => {
        window.print();
    };

    // given a url, return the first level path
    // examople: http://localhost:3000/perrito/hola -> hola
    const getFirstLevelPath = () => {
        return window.location.pathname.split('/')[1];
    }

    const data = getCompanyData();

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-gray-50">
            <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-200 max-w-lg text-center">
                {data && <div className="mb-8">
                    <img src={data.logo} alt="Company Logo" className="mx-auto h-16"/>
                </div>}
                <h1 className="text-3xl font-bold text-green-600 mb-4">Su caso ha sido creado exitosamente</h1>
                <p className="text-gray-700 text-lg mb-6">
                    Su caso ha sido almacenado con éxito. Por favor, guarde el siguiente código. Lo necesitará para
                    seguir el estado de su caso.
                </p>
                <div className="bg-gray-100 p-4 rounded-md text-2xl font-bold text-gray-800 mb-6">
                    Código relacionado a su caso: <span className="text-blue-600">{password}</span>
                </div>
                <p className="text-gray-700 text-lg mb-6">
                    Puede utilizar este código para« seguir las actualizaciones relacionadas con su caso.
                </p>

                <div className="flex justify-center space-x-4">
                    <button
                        onClick={handlePrint}
                        className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
                    >
                        Imprimir
                    </button>
                    <Link
                        to={"/" + getFirstLevelPath()}
                        className="px-6 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition"
                    >
                        Volver a la página principal
                    </Link>
                </div>
            </div>
            <footer className="mt-10 text-gray-500 text-sm">
                Made by{' '}
                <Link
                    to={"/"}
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                >
                    Simple Company SPA
                </Link>
            </footer>
        </div>
    );
};

export default Success;