import APIClient from "../Utils/APIClient";
import Participant from "../Components/Participant.component";
import React, {useEffect, useState} from "react";
import {IParticipantModel} from "../../../server/src/interfaces";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal)

const AdminPage = () => {

    const [participants, setParticipants] = useState<IParticipantModel[]>([]);

    useEffect(() => {
        let client = APIClient.getInstance();
        client.getParticipants({})
            .then((res) => {
                setParticipants(res.data.data);
            })
            .catch((err) => {
                console.error(err);
            })
    }, []);

    const openModal = (participant: IParticipantModel) => {
        MySwal.fire({
            html: <Participant participant={participant} showChat={true} author={"Admin"}/>
        })
    };

    // Create a list where you show a table with all the participants and a button to open the modal
    // This list should be fetched from the server using the APIClient
    // Create all of this using tailwindcss classes
    return (
        <div className="max-w-5xl mx-auto mt-10 px-4">
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Casos</h1>
            <table className="w-full border border-gray-300">
                <thead>
                <tr className="bg-gray-200">
                    <th className="border border-gray-300">Fecha</th>
                    <th className="border border-gray-300">Tipo</th>
                    <th className="border border-gray-300">Empresa</th>
                    <th className="border border-gray-300">Acciones</th>
                </tr>
                </thead>
                <tbody>
                {/* Iterate over the participants and show them in a table */}
                {participants.map((participant, index) => (
                    <tr key={index} className="border border-gray-300">
                        <td className="border border-gray-300">{new Date(participant.createdAt).toLocaleString()}</td>
                        <td className="border border-gray-300">{participant.title}</td>
                        <td className="border border-gray-300">{participant.company || "Portland"}</td>
                        <td className="border border-gray-300">
                            <button onClick={() => openModal(participant)}
                                    className="bg-blue-600 text-white px-4 py-2 rounded-lg">Gestionar
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminPage;