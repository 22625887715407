import { IQuestion} from '../../../../server/src/interfaces';
import {IAnswer} from '../../types/IAnswer';

interface FileQuestionProps {
    question: IQuestion;
    answer: IAnswer;
    handleAnswer: (answer: IAnswer) => void;
}

export const FileQuestion = ({ question, answer, handleAnswer }: FileQuestionProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            let files = Array.from(e.target.files);
            let answerFiles = answer.files ? [...answer.files, ...files] : files;
            handleAnswer({ questionId: question.id, label: question.label, files: answerFiles, type: question.type });
        }
    };

    const deleteFile = (index: number) => {
        let newFiles = answer.files?.filter((file: File, i: number) => i !== index);
        handleAnswer({ questionId: question.id, label: question.label, files: newFiles, type: question.type });
    }

    return (
        <div className="mb-4">
            <div dangerouslySetInnerHTML={{__html: question.label}}/>
            <input
                type="file"
                className="mt-1 block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-indigo-50 file:text-indigo-700
          hover:file:bg-indigo-100"
                required={question.required}
                onChange={handleChange}
            />
            {answer.files && answer.files.length > 0 && (
                <div className="mt-2">
                    {answer.files.map((file: File, index: number) => (
                        <div key={index} className="flex items-center">
                            <span className="mr-2">{file.name}</span>
                            <button
                                type="button"
                                className="text-red-500"
                                onClick={() =>deleteFile(index)}
                            >
                                Eliminar
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
