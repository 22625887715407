import { IQuestion } from '../../../../server/src/interfaces';
import { IAnswer } from '../../types/IAnswer';

interface OptionQuestionProps {
    question: IQuestion;
    answer: IAnswer;
    handleAnswer: (answer: IAnswer) => void;
}

export const OptionQuestion = ({ question, answer, handleAnswer }: OptionQuestionProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (question.type === 'checkbox') {
            let value = answer.value as string[];
            if (e.target.checked) {
                value.push(e.target.value);
            } else {
                value = value.filter((val) => val !== e.target.value);
            }
            handleAnswer({ questionId: question.id, label: question.label, value: value, type: question.type });
            return;
        }
        handleAnswer({ questionId: question.id, label: question.label, value: e.target.value, type: question.type });
    };

    return (
        <div className="mb-4">
            <div dangerouslySetInnerHTML={{__html: question.label}}/>
            {question.hint && <div dangerouslySetInnerHTML={{__html: question.hint}}/>}
            {question.options?.map((option) => (
                <div key={option.value} className="flex items-center mt-2">
                    <input
                        type={question.type}
                        value={option.value}
                        name={question.id}
                        onChange={handleChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                        checked={(answer.value as string[]).includes(option.value)}
                    />
                    <label className="ml-2 block text-sm text-gray-900">{option.label}</label>
                </div>
            ))}
        </div>
    );
};