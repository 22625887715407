import React, { useState } from 'react';
import { IForm, IParticipant } from '../../../server/src/interfaces';
import {useNavigate} from "react-router-dom";
import APIClient from "../Utils/APIClient";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Participant from "../Components/Participant.component";
import getCompanyData from "../Utils/CompanyLocator";
const MySwal = withReactContent(Swal)

interface MainPageProps {
    forms: IForm[];
    // participantData: IParticipant | null; // Participant data fetched after password input
}

const MainPage = ({ forms }: MainPageProps) => {
    const [password, setPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    // Mock function to verify password and open modal
    const verifyPassword = () => {
        let APIclient = APIClient.getInstance();
        APIclient.getParticipant({password})
            .then((res) => {
                console.log(res.data);
                MySwal.fire({
                    html: <Participant participant={res.data} showChat={true} author={"usuario"}/>
                })
            })
            .catch(async (err) => {
                await MySwal.fire({
                    title: 'Error',
                    text: 'No se encontró un caso con ese código',
                    icon: 'error'
                })
            })
    };

    const goToFormPage = (form : IForm) => {
        // Redirect to form page and pass the form as a prop
        navigate('form', { state: { form } });
    }

    const data = getCompanyData();

    return (
        <div className="max-w-5xl mx-auto mt-10 px-4">
            {data &&
             <>
                 {/* Company Logo */}
                 <div className="flex justify-center mb-8">
                     <img src={data.logo} alt="Company Logo" className="h-16"/>
                 </div>
                 {/* Password-protected Participant Details (opens modal) */}
                 <section className="mb-12">
                     <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">{data.title}</h2>
                     <p className="text-gray-700 mb-4">{data.description}</p>

                     <div className="bg-white p-8 rounded-lg shadow-md border border-gray-200">
                         <label htmlFor="password" className="block text-lg font-medium text-gray-700 mb-4">
                             Haz seguimiento a tu caso
                         </label>
                         <input
                             id="password"
                             type="password"
                             value={password}
                             onChange={(e) => setPassword(e.target.value)}
                             className="w-full px-4 py-2 border rounded-md mb-4"
                             placeholder="Ingrsa tu código de caso"
                         />
                         <button
                             onClick={verifyPassword}
                             className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
                         >
                             Consultar
                         </button>
                     </div>
                 </section>

                 {/* Available Forms */}
                 <section>
                     <h2 className="text-3xl font-bold text-gray-800 mb-6">Formularios disponibles</h2>
                     <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                         {forms.map((form) => (
                             <div key={form.title} className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
                                 <h3 className="text-xl font-bold mb-2">{form.title}</h3>
                                 <p className="text-gray-700 mb-4">{form.shortDescription}</p>
                                 <button onClick={() => goToFormPage(form)}
                                         className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition">
                                     Crear Caso
                                 </button>
                             </div>
                         ))}
                     </div>
                 </section>
             </>
            }

            {/* Footer */}
            <footer className="mt-12 text-center">
                <p className="text-gray-500 text-sm">
                    Made by{' '}
                    <a href="https://simplecompany.cl" target="_blank" rel="noopener noreferrer"
                       className="text-blue-600 hover:text-blue-800">
                        Simple Company SPA
                    </a>
                </p>
            </footer>
        </div>
    );
};

export default MainPage;