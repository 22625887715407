import React, {Component, useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import { IForm, ISection, IQuestion } from '../../../server/src/interfaces';
import { IAnswer } from '../types/IAnswer';
import { TextQuestion } from '../Components/Questions/TextQuestion.component';
import { OptionQuestion } from '../Components/Questions/OptionQuestion.component';
import { SelectQuestion } from '../Components/Questions/SelectQuestion.component';
import { FileQuestion } from '../Components/Questions/FileQuestion.component';
import APIClient from "../Utils/APIClient";
import {useNavigate} from "react-router-dom";
import getCompanyData from "../Utils/CompanyLocator";

const MySwal = withReactContent(Swal)

interface FormPageProps {
    form: IForm;
}

const FormPage = ({ form }: FormPageProps) => {
    const [answers, setAnswers] = useState<IAnswer[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        let answers : IAnswer[] = [];
            form.sections.map((section) => {
          section.questions.map((question)  => {
              switch (question.type) {
                    case 'checkbox':
                        answers.push({questionId: question.id, label: question.label, type: question.type, value: [], files: []});
                        break;
                    default:
                        answers.push({questionId: question.id, label: question.label, type: question.type, value: '', files: []});
                        break;
              }
          })
        })
        setAnswers(answers);
    }, [form]);

    const handleAnswerUpdate = (newAnswer: IAnswer) => {
        setAnswers((prevAnswers) => {
            const existingAnswer = prevAnswers.find((answer) => answer.questionId === newAnswer.questionId);
            if (existingAnswer) {
                return prevAnswers.map((answer) => answer.questionId === newAnswer.questionId ? newAnswer : answer);
            } else {
                return [...prevAnswers, newAnswer];
            }
        });
    };

    const getFormQuestions = (form: IForm): IQuestion[] => {
        return form.sections.reduce((acc: IQuestion[], section : ISection) => {
            return [...acc, ...section.questions];
        }, []);
    }

    const getQuestionAnswer = (questionId: string): IAnswer | undefined => {
        return answers.find((answer) => answer.questionId === questionId);
    }

    const validateAnswers = (answers: IAnswer[]): boolean => {
        return getFormQuestions(form).every((question) => {
            if (!shouldRenderQuestion(question)) return true;
            const answer = answers.find((ans) => ans.questionId === question.id);
            return validateAnswer(question, answer);
        });
    }

    const validateAnswer = (question: IQuestion ,answer: IAnswer | undefined): boolean => {
        if (!question.required) return true;
        if (question.required && !answer) return false;

        switch (question.type) {
            case 'text':
            case 'email':
            case 'textarea':
            case 'radio':
            case 'select':
                return answer?.value !== '';
            case 'checkbox':
                return answer?.value !== undefined && answer.value.length > 0;
            case 'file':
                return answer?.files !== undefined && answer.files?.length > 0;
            default:
                return false;
        }
    }

    const showValidationError = (answers: IAnswer[]) => {
        let messages : React.ReactElement[] = [];
        getFormQuestions(form).forEach((question) => {
            if (!shouldRenderQuestion(question)) return;
            const answer = answers.find((ans) => ans.questionId === question.id);
            if (!validateAnswer(question, answer)) {
                messages.push(<li className="" key={question.id}>{question.label}</li>);
            }
        });

        MySwal.fire({
            icon: 'error',
            title: <p>Debes completar todos los campos</p>,
            html: <ul className="mx-12 list-disc list-inside">{messages}</ul>,
        }).then(() => {
            return;
        })
    }

    const shouldRenderQuestion = (question: IQuestion): boolean => {
        if (!question.conditional) return true;
        const triggeringAnswer = answers.find((ans) => ans.questionId === question.conditional?.id);
        return triggeringAnswer?.value === question.conditional?.value;
    };

    const renderQuestionComponent = (question: IQuestion) => {
        if (!shouldRenderQuestion(question)) return null;
        let answer = getQuestionAnswer(question.id);

        if (!answer) return null;

        switch (question.type) {
            case 'text':
            case 'email':
            case 'textarea':
                return <TextQuestion answer={answer} question={question} handleAnswer={handleAnswerUpdate} />;
            case 'radio':
            case 'checkbox':
                return <OptionQuestion answer={answer} question={question} handleAnswer={handleAnswerUpdate} />;
            case 'select':
                return <SelectQuestion answer={answer} question={question} handleAnswer={handleAnswerUpdate} />;
            case 'file':
                return <FileQuestion answer={answer} question={question} handleAnswer={handleAnswerUpdate} />;
            default:
                return null;
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateAnswers(answers)) {
            showValidationError(answers);
            return;
        }

        const data = getCompanyData();

        let APIclient = APIClient.getInstance();
        try {
            let response = await APIclient.createParticipant(form.title, answers, data.name);
            await Swal.fire({
                icon: 'success',
                title: 'Caso generado correctamente',
                text: response.data.message,
            })
            let password = response.data.password;
            let URLPath = window.location.pathname.split('/')[1];
            navigate(`/${URLPath}/success`, {state: {password}});

        } catch (e) {
            await Swal.fire({
                icon: 'error',
                title: 'Error al enviar las respuestas',
                text: 'Por favor, intenta nuevamente',
            })
        }
        console.log('Submitted Answers:', answers);
        console.log('Answers are valid:', validateAnswers(answers));
    };

    const data = getCompanyData();

    return (
        <div className="max-w-4xl mx-auto mt-10 px-4">
            {data &&
            <div className="flex justify-center mb-8">
                <img src={data.logo} alt="Company Logo"
                     className="h-16"/>
            </div>}
            <h1 className="text-3xl font-bold text-gray-900 mb-8 text-left border-b-2 border-gray-200 pb-4">
                {form.title}
            </h1>
            <p className="text-gray-700 mb-8">{form.description}</p>
            <form onSubmit={handleSubmit} className="bg-gray-50 p-8 rounded-lg shadow-lg">
                {form.sections.map((section) => (
                    <div key={section.title} className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{section.title}</h2>
                        {section.questions.map((question) => (
                            <div key={question.id} className="mb-4">
                                {renderQuestionComponent(question)}
                            </div>
                        ))}
                    </div>
                ))}
                <div className="mt-8 flex justify-end">
                    <button
                        type="submit"
                        className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-medium rounded-lg shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-150"
                    >
                        Enviar
                    </button>
                </div>
            </form>
            <footer className="mt-12 text-center">
                <p className="text-gray-500 text-sm">
                    Made by{' '}
                    <a
                        href="https://simplecompany.cl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-indigo-600 hover:text-indigo-800 font-semibold"
                    >
                        Simple Company SPA
                    </a>
                </p>
            </footer>
        </div>
    );
};

export default FormPage;