import axios, {AxiosInstance, AxiosPromise} from "axios";
import {IAnswer} from "../types/IAnswer";
import {IMessage} from "../../../server/src/interfaces";

class APIClient {

    private static instance: APIClient;
    private _http: AxiosInstance;

    private constructor() {
        this._http = axios.create({
            // baseURL: "http://localhost:5000",
            baseURL: "https://formularios-server.simplecompany.cl",
            timeout: 30 * 1000,
        })
    }

    public static getInstance(): APIClient {
        if (!APIClient.instance) {
            APIClient.instance = new APIClient();
        }
        return APIClient.instance;
    }

    public createParticipant(title: string,answers: IAnswer[], company: string): AxiosPromise {
        let formData = new FormData();
        formData.append('title', title);
        formData.append('company', company);
        let answerData: string[] = [];

        answers.forEach((answer) => {
            let data = {...answer};
            delete data.files;
            answerData.push(JSON.stringify(data));

            if (answer.files) {
                answer.files.forEach((file) => {
                    formData.append(`files`, file);
                });
            }
        });

        formData.append('answers', JSON.stringify(answerData));

        return this._http.post('/api/participants/', formData,  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    public getParticipants(data : {password?: string, id?: string}): AxiosPromise {
        return this._http.get(`/api/participants/`);
    }

    public getParticipant(data : {password?: string, id?: string}): AxiosPromise {
        let {password, id} = data;
        if (id) {
            return this._http.get(`/api/participants/query/?id=${id}`);
        } else if (password) {
            return this._http.get(`/api/participants/query/?password=${password}`);
        }
        throw new Error("Invalid request");
    }

    public addMessage(id: string, message: IMessage): AxiosPromise {
        return this._http.post(`/api/participants/${id}/messages/`, message, {headers: {'Content-Type': 'application/json'}});
    }



}

export default APIClient;