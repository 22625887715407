import React, {useState} from 'react';
import {IParticipantModel} from "../../../server/src/interfaces";
import Chat from "./Chat/Chat.component";
import APIClient from "../Utils/APIClient";

interface ParticipantProps {
    participant: IParticipantModel;
    showChat: boolean;
    author: string;
}

const Participant = ({ participant, showChat, author }: ParticipantProps) => {

    const [participantLive, setParticipantLive] = useState<IParticipantModel>(participant);

    const addMessage = (message : string) => {
        // Add message to the participant
        let client = APIClient.getInstance();
        client.addMessage(participantLive._id.toString(), {message, author})
            .then((res) => {
                setParticipantLive(res.data);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const renderAnswers = () =>
        participant.answers.map((answer, showChat) => {
            switch (answer.type) {
                case 'text':
                case "email":
                case "textarea":
                    return (
                        <div key={answer.questionId} className="mb-4">
                            <h3 className="text-lg font-semibold text-gray-800 mb-2">{answer.label}</h3>
                            <p className="text-gray-700">{answer.value}</p>
                        </div>
                    );
                case 'checkbox':
                case 'radio':
                    return (
                        <div key={answer.questionId} className="mb-4">
                            <h3 className="text-lg font-semibold text-gray-800 mb-2">{answer.label}</h3>
                            <ul className="list-disc list-inside">
                                //check if answer.value is an array
                                {Array.isArray(answer.value) && answer.value?.map((option : string) => (
                                    <li key={option} className="text-gray-700">{option}</li>
                                ))}
                            </ul>
                        </div>
                    );
                case 'select':
                    return (
                        <div key={answer.questionId} className="mb-4">
                            <h3 className="text-lg font-semibold text-gray-800 mb-2">{answer.label}</h3>
                            <p className="text-gray-700">{answer.value}</p>
                        </div>
                    );
                case 'file':
                    return (
                        <div key={answer.questionId} className="mb-4">
                            <h3 className="text-lg font-semibold text-gray-800 mb-2">{answer.label}</h3>
                            <ul className="list-disc list-inside">
                                {answer.files?.map((file) => (
                                    <li key={file.Key} className="text-gray-700">'
                                        <a href={file.Location} target="_blank" className="text-blue-600 hover:text-blue-800">
                                            {file.Key}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                default:
                    return null;
        }});


    return (
        <div className="max-w-4xl mx-auto mt-10 px-4">
            <h1 className="text-3xl font-bold text-gray-900 mb-8 text-left border-b-2 border-gray-200 pb-4">
                Detalles del Caso
            </h1>
            <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">{participantLive.title}</h2>
                <p className="text-gray-700 text-sm mb-8">{new Date(participantLive.createdAt).toLocaleString()}</p>
                {renderAnswers()}
            </div>
        {/*    Mensajes */}
            {showChat && <Chat messages={participantLive.messages} addMessage={addMessage} />}
        </div>
    );
}

export default Participant;