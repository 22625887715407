import {IForm} from "../../../server/src/interfaces";
// PORTLAND

export const PORTLAND_DEFAULT_FORM_DATA : IForm = {
    title: 'Consulta/Denuncia anónima',
    shortDescription: 'Crear un caso nuevo te permitirá realizar una denuncia o consulta sobre un tema específico que desees reportar.',
    description: 'Por favor, completa el siguiente formulario con la información solicitada para poder ayudarte de la mejor manera posible.',
    sections: [
        {
            title: 'Información de contacto',
            questions: [{
                label: "¿Quieres hacer tu denuncia de forma anónima?",
                id: "anonymous",
                type: "radio",
                options: [
                    {label: "Sí", value: "si"},
                    {label: "No", value: "no"}
                ],
                required: true,
            },
                {
                    label:"Nombre completo",
                    id: "name",
                    type: "text",
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                }, {
                    label: "Correo electrónico",
                    id: "email",
                    type: "email",
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                },
                {
                    label: "Teléfono",
                    id: "phone",
                    type: "text",
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                }, {
                    label: "Horario preferido para ser contactado",
                    id: "contact-time",
                    type: "select",
                    options: [
                        {label: "Mañana (08:00 - 12:00)", value: "mañana"},
                        {label: "Tarde (12:00 - 18:00)", value: "tarde"},
                        {label: "Noche (18:00 - 22:00)", value: "noche"},
                    ],
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                }
            ]

        },{
            title: 'Relación con la unidad de negocio',
            questions: [{
                label: "Tipo de caso",
                id: "case-type",
                type: "select",
                options: [
                    {label: "Consulta", value: "consulta"},
                    {label: "Denuncia", value: "denuncia"},
                ],
                required: true,
            },

                {
                    label: "Unidad de negocio desde donde consulta",
                    id: "business-unit",
                    type: "select",
                    options: [
                        {label: "Oficina Vitacura", value: "Oficina Vitacura"},
                        {label: "Planta La Granja", value: "Planta La Granja"},
                        {label: "Planta San Antonio", value: "Planta San Antonio"},
                    ],
                    required: true
                },


                {
                    label: "¿Cual es tu relación con esta unidad de negocio?",
                    id: "relationship",
                    type: "select",
                    options: [
                        {label: "Colaborador", value: "empleado"},
                        {label: "Cliente", value: "cliente"},
                        {label: "Proveedor", value: "proveedor"},
                        {label: "Otro", value: "otro"},
                    ],
                    required: true,
                }, {
                    label: "Especifica",
                    id: "relationship-other",
                    type: "text",
                    required: true,
                    conditional: {
                        id: "relationship",
                        value: "otro"
                    }
                }
            ]
        }, {
            title: "Detalles del caso",
            questions: [
                {
                    label: "Descripción",
                    id: "description",
                    type: "textarea",
                    required: true,
                }, {
                    label: "Adjuntar archivo",
                    id: "file",
                    type: "file",
                    required: false,
                }
            ]
        }
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
};

export const PORTLAND_DEFAULT_KARIN_FORM_DATA : IForm = {
    title: 'Denuncias Ley Karin (Ley 21.643)',
    shortDescription: 'Crear un nuevo caso te permitirá realizar la denuncia por acoso laboral, acoso sexual o violencia de terceros.',
    description: 'En Grupo Portland estamos comprometidos a proteger el bienestar y la salud mental de nuestros colaboradores, es por esa razón, que hemos tomado un rol protagónico en poder abordar las denuncias de acoso laboral, de acoso sexual y de violencia de terceros que pudiesen existir en nuestra organización. Por lo tanto, el presente Sistema de Denuncias tiene como objetivo investigar los hechos presentados, alineados a nuestro procedimiento de investigación estipulado en nuestro Reglamento Interno.\n' +
        '\n' +
        'Una vez realizada la denuncia, dispondremos de un plazo de tres días para realizar la investigación interna, y se remitirá los antecedentes a la Inspección del Trabajo respectivo para que esta realice la investigación de los hechos. Sin perjuicio de lo anterior, usted tiene el derecho de presentar la denuncia directamente a la Inspección de Trabajo competente.',
    sections: [
        {
            title: 'Datos de el/la afectado/a',
            questions: [{
                label: "¿Es usted el o la afectada? ",
                id: "isVictim",
                type: "radio",
                options: [
                    {label: "Sí", value: "si"},
                    {label: "No", value: "no"}
                ],
                required: true,
            }, {
                label: "Hecho denunciado",
                id: "type",
                type: "checkbox",
                options: [
                    {label: "Acoso laboral", value: "acoso-laboral"},
                    {label: "Acoso sexual", value: "acoso-sexual"},
                    {label: "Violencia de terceros", value: "violencia-terceros"},
                ],
                required: true
            }, {
                label: "Relación con el o la afectada",
                hint: "EJ: Jefatura, compañero de trabajo, testigo, etc.",
                id: "relationship",
                type: "text",
                required: true,
                conditional: {
                    id: "isVictim",
                    value: "no"
                }
            }, {
                label: "Nombre completo de la afectada",
                id: "victim-name",
                type: "text",
                required: true,
                conditional: {
                    id: "isVictim",
                    value: "no"
                }
            }, {
                label: "Indica tu nombre completo",
                id: "name",
                hint: "Primer nombre y apellidos",
                type: "text",
                required: true,
            }, {
                label: "Indica tu RUT",
                id: "rut",
                hint: "Rut sin puntos ni guión ni digito verificador",
                type: "text",
                required: true,
            }, {
                label: "Indica tu cargo y área de trabajo",
                id: "position",
                type: "text",
                required: true,
            }, {
                label: "Indica tu correo electrónico",
                id: "email",
                type: "email",
                required: true,
            }, {
                label: "Indica tu teléfono",
                id: "phone",
                type: "text",
                required: true,
            }]
        }, {
            title: 'Antecedentes del denunciado',
            questions: [{
                label: "Nombre completo del denunciado",
                id: "accused-name",
                type: "text",
                required: true,
            }, {
                label: "Indica el cargo y área de trabajo del denunciado",
                id: "accused-position",
                type: "text",
                required: true,
            }, {
                label: "Indica el nombre de la empresa del denunciado",
                id: "accused-company",
                type: "text",
                required: true,
            }]
        }, {
            title: 'Detalles del caso',
            questions: [{
                label: "Indique la fecha de ocurrencia de los hechos (dd-mm-yyyy)",
                id: "date",
                type: "text",
                required: true,
            }, {
                label: "En el siguiente recuadro, debes proporcionar una descripción detallada de los hechos que motivan tu denuncia",
                hint: "<p>Incluye la siguiente información:</p>" +
                    "<ul>" +
                    "<li><span class='font-bold'>Relación de los hechos:</span> Describe de manera clara y precisa las conductas o situaciones que consideras constitutivas de acoso laboral, acoso sexual o violencia en el trabajo.</li>" +
                    "<li><span class='font-bold'>Fechas y horas:</span> Especifica las fechas y, si es posible, las horas en que ocurrieron los hechos denunciados. Si los hechos se han repetido, menciona todas las ocasiones que recuerdes.</li>" +
                    "<li><span class='font-bold'>Personas involucradas: </span> Identifica a la persona o personas que consideras responsables de las conductas denunciadas, así como a cualquier testigo que pueda corroborar tus declaraciones.</li>" +
                    "<li><span class='font-bold'>Otros detalles relevantes:</span> Incluye cualquier otro detalle que consideres importante para la comprensión de los hechos, como el lugar donde ocurrieron, si hubo testigos, si existen pruebas documentales (correos electrónicos, mensajes, etc.) o cualquier otra información que pueda ser útil para la investigación.</li>" +
                    "</ul>" +
                    "<p>Es fundamental que tu descripción sea lo más completa y detallada posible, ya que esto facilitará la labor de investigación y permitirá determinar la veracidad de los hechos denunciados. Recuerda que la información que proporciones será tratada con absoluta confidencialidad.</p>",
                id: "description",
                type: "textarea",
                required: true,
            }, {
                label: "Archivos adjuntos",
                hint: "En el siguiente campo, puedes adjuntar cualquier documento que respalde tu denuncia, como capturas de pantalla, fotografías, videos, archivos o audios. Si el tamaño, cantidad, o formato de los archivos impide adjuntarlos en este formulario, podrás entregarlos directamente al investigador durante el proceso." +
                    "Es importante que proporciones todas las pruebas que consideres relevantes para respaldar tu denuncia, ya que esto facilitará la investigación y ayudará a esclarecer los hechos.",
                id: "file",
                type: "file",
                required: false,
            }, {
                label: "Declaración de confidencialidad",
                hint: "<ol class='list-decimal'>" +
                    "<li>Conozco el procedimiento: Me he informado sobre el procedimiento que sigue la Empresa para recibir e investigar denuncias de acoso laboral, sexual y violencia en el lugar de trabajo, de acuerdo con lo establecido en el Reglamento Interno de Orden, Higiene y Seguridad.</li>" +
                    "<li>Medidas de Resguardo: Me comprometo a aceptar y cumplir con las medidas de resguardo que disponga la Empresa, las que pueden considerar entre otras la separación de los espacios físicos, cambio de lugar de trabajo, la redistribución del tiempo de la jornada y proporcionar a la persona denunciante atención psicológica temprana.</li>" +
                    "<li>Sé que puedo acudir a la Dirección del Trabajo: Estoy en conocimiento de que puedo denunciar estos mismos hechos ante la Dirección del Trabajo, sin embargo, deseo que mi empleador o quien corresponda conforme a la ley, realice la investigación de mi denuncia.</li>" +
                    "<li>Mantendré la confidencialidad: Me comprometo a guardar total reserva sobre la información que conozca durante este proceso. Entiendo que la investigación es privada y no puedo compartir detalles con nadie dentro o fuera de la empresa.</li>" +
                    "<li>Comprendo las consecuencias de una denuncia falsa: Soy consciente de que, si presento una denuncia falsa, puedo ser sancionado(a) de acuerdo con lo establecido en el Reglamento Interno de Orden, Higiene y Seguridad de la Empresa, sin perjuicio de las acciones legales que pueda tener el falsamente denunciado para reparar el perjuicio sufrido por la denuncia.</li>" +
                    "</ol>",
                id: "confidential",
                type: "checkbox",
                options: [
                    {label: "Acepto y declaro estar de acuerdo", value: "si"},
                ],
                required: true,
            }]
        }
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
}

// PCFACTORY

export const PCFACTORY_DEFAULT_FORM_DATA : IForm = {
    title: 'Consulta/Denuncia anónima',
    shortDescription: 'Crear un caso nuevo te permitirá realizar una denuncia o consulta sobre un tema específico que desees reportar.',
    description: 'Por favor, completa el siguiente formulario con la información solicitada para poder ayudarte de la mejor manera posible.',
    sections: [
        {
            title: 'Información de contacto',
            questions: [{
                label: "¿Quieres hacer tu denuncia de forma anónima?",
                id: "anonymous",
                type: "radio",
                options: [
                    {label: "Sí", value: "si"},
                    {label: "No", value: "no"}
                ],
                required: true,
            },
                {
                    label:"Nombre completo",
                    id: "name",
                    type: "text",
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                }, {
                    label: "Correo electrónico",
                    id: "email",
                    type: "email",
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                },
                {
                    label: "Teléfono",
                    id: "phone",
                    type: "text",
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                }, {
                    label: "Horario preferido para ser contactado",
                    id: "contact-time",
                    type: "select",
                    options: [
                        {label: "Mañana (08:00 - 12:00)", value: "mañana"},
                        {label: "Tarde (12:00 - 18:00)", value: "tarde"},
                        {label: "Noche (18:00 - 22:00)", value: "noche"},
                    ],
                    required: true,
                    conditional: {
                        id: "anonymous",
                        value: "no"
                    }
                }
            ]

        },{
            title: 'Relación con la unidad de negocio',
            questions: [{
                label: "Tipo de caso",
                id: "case-type",
                type: "select",
                options: [
                    {label: "Consulta", value: "consulta"},
                    {label: "Denuncia", value: "denuncia"},
                ],
                required: true,
            },

                {
                    label: "Unidad de negocio desde donde consulta",
                    id: "business-unit",
                    type: "select",
                    options: [
                        {label: "Oficina central", value: "Oficina central"},
                    ],
                    required: true
                },


                {
                    label: "¿Cual es tu relación con esta unidad de negocio?",
                    id: "relationship",
                    type: "select",
                    options: [
                        {label: "Colaborador", value: "empleado"},
                        {label: "Cliente", value: "cliente"},
                        {label: "Proveedor", value: "proveedor"},
                        {label: "Otro", value: "otro"},
                    ],
                    required: true,
                }, {
                    label: "Especifica",
                    id: "relationship-other",
                    type: "text",
                    required: true,
                    conditional: {
                        id: "relationship",
                        value: "otro"
                    }
                }
            ]
        }, {
            title: "Detalles del caso",
            questions: [
                {
                    label: "Descripción",
                    id: "description",
                    type: "textarea",
                    required: true,
                }, {
                    label: "Adjuntar archivo",
                    id: "file",
                    type: "file",
                    required: false,
                }
            ]
        }
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
};

export const PCFACTORY_DEFAULT_KARIN_FORM_DATA : IForm = {
    title: 'Denuncias Ley Karin (Ley 21.643)',
    shortDescription: 'Crear un nuevo caso te permitirá realizar la denuncia por acoso laboral, acoso sexual o violencia de terceros.',
    description: 'En PCFactory estamos comprometidos a proteger el bienestar y la salud mental de nuestros colaboradores, es por esa razón, que hemos tomado un rol protagónico en poder abordar las denuncias de acoso laboral, de acoso sexual y de violencia de terceros que pudiesen existir en nuestra organización. Por lo tanto, el presente Sistema de Denuncias tiene como objetivo investigar los hechos presentados, alineados a nuestro procedimiento de investigación estipulado en nuestro Reglamento Interno.\n' +
        '\n' +
        'Una vez realizada la denuncia, dispondremos de un plazo de tres días para realizar la investigación interna, y se remitirá los antecedentes a la Inspección del Trabajo respectivo para que esta realice la investigación de los hechos. Sin perjuicio de lo anterior, usted tiene el derecho de presentar la denuncia directamente a la Inspección de Trabajo competente.',
    sections: [
        {
            title: 'Datos de el/la afectado/a',
            questions: [{
                label: "¿Es usted el o la afectada? ",
                id: "isVictim",
                type: "radio",
                options: [
                    {label: "Sí", value: "si"},
                    {label: "No", value: "no"}
                ],
                required: true,
            }, {
                label: "Hecho denunciado",
                id: "type",
                type: "checkbox",
                options: [
                    {label: "Acoso laboral", value: "acoso-laboral"},
                    {label: "Acoso sexual", value: "acoso-sexual"},
                    {label: "Violencia de terceros", value: "violencia-terceros"},
                ],
                required: true
            }, {
                label: "Relación con el o la afectada",
                hint: "EJ: Jefatura, compañero de trabajo, testigo, etc.",
                id: "relationship",
                type: "text",
                required: true,
                conditional: {
                    id: "isVictim",
                    value: "no"
                }
            }, {
                label: "Nombre completo de la afectada",
                id: "victim-name",
                type: "text",
                required: true,
                conditional: {
                    id: "isVictim",
                    value: "no"
                }
            }, {
                label: "Indica tu nombre completo",
                id: "name",
                hint: "Primer nombre y apellidos",
                type: "text",
                required: true,
            }, {
                label: "Indica tu RUT",
                id: "rut",
                hint: "Rut sin puntos ni guión ni digito verificador",
                type: "text",
                required: true,
            }, {
                label: "Indica tu cargo y área de trabajo",
                id: "position",
                type: "text",
                required: true,
            }, {
                label: "Indica tu correo electrónico",
                id: "email",
                type: "email",
                required: true,
            }, {
                label: "Indica tu teléfono",
                id: "phone",
                type: "text",
                required: true,
            }]
        }, {
            title: 'Antecedentes del denunciado',
            questions: [{
                label: "Nombre completo del denunciado",
                id: "accused-name",
                type: "text",
                required: true,
            }, {
                label: "Indica el cargo y área de trabajo del denunciado",
                id: "accused-position",
                type: "text",
                required: true,
            }, {
                label: "Indica el nombre de la empresa del denunciado",
                id: "accused-company",
                type: "text",
                required: true,
            }]
        }, {
            title: 'Detalles del caso',
            questions: [{
                label: "Indique la fecha de ocurrencia de los hechos (dd-mm-yyyy)",
                id: "date",
                type: "text",
                required: true,
            }, {
                label: "En el siguiente recuadro, debes proporcionar una descripción detallada de los hechos que motivan tu denuncia",
                hint: "<p>Incluye la siguiente información:</p>" +
                    "<ul>" +
                    "<li><span class='font-bold'>Relación de los hechos:</span> Describe de manera clara y precisa las conductas o situaciones que consideras constitutivas de acoso laboral, acoso sexual o violencia en el trabajo.</li>" +
                    "<li><span class='font-bold'>Fechas y horas:</span> Especifica las fechas y, si es posible, las horas en que ocurrieron los hechos denunciados. Si los hechos se han repetido, menciona todas las ocasiones que recuerdes.</li>" +
                    "<li><span class='font-bold'>Personas involucradas: </span> Identifica a la persona o personas que consideras responsables de las conductas denunciadas, así como a cualquier testigo que pueda corroborar tus declaraciones.</li>" +
                    "<li><span class='font-bold'>Otros detalles relevantes:</span> Incluye cualquier otro detalle que consideres importante para la comprensión de los hechos, como el lugar donde ocurrieron, si hubo testigos, si existen pruebas documentales (correos electrónicos, mensajes, etc.) o cualquier otra información que pueda ser útil para la investigación.</li>" +
                    "</ul>" +
                    "<p>Es fundamental que tu descripción sea lo más completa y detallada posible, ya que esto facilitará la labor de investigación y permitirá determinar la veracidad de los hechos denunciados. Recuerda que la información que proporciones será tratada con absoluta confidencialidad.</p>",
                id: "description",
                type: "textarea",
                required: true,
            }, {
                label: "Archivos adjuntos",
                hint: "En el siguiente campo, puedes adjuntar cualquier documento que respalde tu denuncia, como capturas de pantalla, fotografías, videos, archivos o audios. Si el tamaño, cantidad, o formato de los archivos impide adjuntarlos en este formulario, podrás entregarlos directamente al investigador durante el proceso." +
                    "Es importante que proporciones todas las pruebas que consideres relevantes para respaldar tu denuncia, ya que esto facilitará la investigación y ayudará a esclarecer los hechos.",
                id: "file",
                type: "file",
                required: false,
            }, {
                label: "Declaración de confidencialidad",
                hint: "<ol class='list-decimal'>" +
                    "<li>Conozco el procedimiento: Me he informado sobre el procedimiento que sigue la Empresa para recibir e investigar denuncias de acoso laboral, sexual y violencia en el lugar de trabajo, de acuerdo con lo establecido en el Reglamento Interno de Orden, Higiene y Seguridad.</li>" +
                    "<li>Medidas de Resguardo: Me comprometo a aceptar y cumplir con las medidas de resguardo que disponga la Empresa, las que pueden considerar entre otras la separación de los espacios físicos, cambio de lugar de trabajo, la redistribución del tiempo de la jornada y proporcionar a la persona denunciante atención psicológica temprana.</li>" +
                    "<li>Sé que puedo acudir a la Dirección del Trabajo: Estoy en conocimiento de que puedo denunciar estos mismos hechos ante la Dirección del Trabajo, sin embargo, deseo que mi empleador o quien corresponda conforme a la ley, realice la investigación de mi denuncia.</li>" +
                    "<li>Mantendré la confidencialidad: Me comprometo a guardar total reserva sobre la información que conozca durante este proceso. Entiendo que la investigación es privada y no puedo compartir detalles con nadie dentro o fuera de la empresa.</li>" +
                    "<li>Comprendo las consecuencias de una denuncia falsa: Soy consciente de que, si presento una denuncia falsa, puedo ser sancionado(a) de acuerdo con lo establecido en el Reglamento Interno de Orden, Higiene y Seguridad de la Empresa, sin perjuicio de las acciones legales que pueda tener el falsamente denunciado para reparar el perjuicio sufrido por la denuncia.</li>" +
                    "</ol>",
                id: "confidential",
                type: "checkbox",
                options: [
                    {label: "Acepto y declaro estar de acuerdo", value: "si"},
                ],
                required: true,
            }]
        }
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
}