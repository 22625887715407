const DATA : any = {
    "portland": {
        name: "Portland",
        logo: "https://storage.googleapis.com/bwg-denuncias/portland.png",
        title: "Bienvenido al sistema de consultas/denuncias de Portland",
        text: "Nos enorgullece fortalecer los valores éticos impresos en nuestra cultura nos definen al momento de actuar. Con el objeto de mantener altos estándares de integridad en los negocios, hemos dispuesto a nuestros colaboradores, ejecutivos, directores de la compañía y de terceros el presente Canal de Integridad para que, de manera confidencial y anónima, realicen consultas sobre materias éticas o informen de infracciones o transgresiones a cualquier ley o normativa vigente , así como transgresiones a las normas internas de la compañía respectiva, o a los principios o valores establecidos en el Código de Integridad."
    },
    "pcfactory": {
        name: "PC Factory",
        logo: "https://storage.googleapis.com/bwg-denuncias/pcfactory.jpg",
        title: "Bienvenido al sistema de consultas/denuncias de PC Factory",
        text: "En PC Factory, nos preocupamos por mantener un ambiente de trabajo seguro y respetuoso para todos nuestros colaboradores. Por ello, hemos dispuesto este canal de comunicación para que puedas realizar consultas o denuncias de manera confidencial y anónima. Juntos, podemos mantener un ambiente de trabajo seguro y respetuoso para todos."
    }
}


// if url is www.example.com/poland/perrito/example
// it returns "poland"
const getURLPath = () => {
    return window.location.pathname.split("/")[1];
}

const getCompanyData = () :any => {
    let companyName = getURLPath();
    return DATA[companyName];
}

export default getCompanyData;