import {IMessageModel} from "../../../../server/src/interfaces";
import {useState} from "react";

interface ChatProps {
    messages: IMessageModel[]
    addMessage: (message: string) => void
}
const Chat = ({messages, addMessage} : ChatProps) => {

    const [message, setMessage] = useState<string>("");

    const dateToLocal = (date: Date) => {
        return new Date(date).toLocaleString();
    }

    const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        addMessage(message);
        setMessage("");
    }

    return (
        <div className="max-w-4xl mx-auto mt-10 px-4">
            <div className="flex flex-col space-y-4">
                {messages.length == 0 && <p className="text-gray-800">No hay mensajes</p>}
                {messages.map((message, index) => (
                    <div key={index} className="flex flex-col space-y-2">
                        {/* Autor y fecha */}
                        <div>
                            <p className="text-gray-600">{message.author}</p>
                            <p className="text-gray-500">{dateToLocal(message.createdAt)}</p>
                        </div>
                        {/* Mensaje */}
                        <div>
                            <p className="text-gray-800">{message.message}</p>
                        </div>
                    </div>
                ))}
            </div>
        {/*    Formulario para agregar un mensaje*/}
            <form className="mt-4" onSubmit={formSubmit}>
                <label htmlFor="message" className="text-gray-800">Mensaje</label>
                <textarea name="message" id="message" className="w-full rounded-lg border border-gray-300 p-2" value={message} onChange={(e) => {setMessage(e.target.value)}}></textarea>
                <button type="submit" className="w-full bg-blue-600 text-white rounded-lg px-4 py-2 mt-2">Enviar</button>
            </form>
        </div>
    );
}

export default Chat;