import { IQuestion } from '../../../../server/src/interfaces';
import { IAnswer } from '../../types/IAnswer';
interface SelectQuestionProps {
    question: IQuestion;
    answer: IAnswer;
    handleAnswer: (answer: IAnswer) => void;
}

export const SelectQuestion = ({ question, answer, handleAnswer }: SelectQuestionProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleAnswer({ questionId: question.id, label: question.label, value: e.target.value, type: question.type });
    };

    return (
        <div className="mb-4">
            <div dangerouslySetInnerHTML={{__html: question.label}}/>
            <select
                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required={question.required}
                onChange={handleChange}
            >
                <option value="">Selecciona una opción</option>
                {question.options?.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};