import {useState} from "react";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
    // Create a Login Page with a form that has two inputs, one for the email and one for the password
    // The form should have a submit button that logs the email and password to the console
    // Use the useState hook to store the email and password
    // Use the onChange event to update the email and password
    // Use the onSubmit event to log the email and password

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (email == "admin@simpleformularios.cl" && password == "Zd541qJnX0RC") {
            navigate("/admin/participants");
        }
    }


    return (
        <div className="max-w-4xl mx-auto mt-10 px-4">
            <form onSubmit={handleSubmit}>
                <label htmlFor="email" className="text-gray-800">Email</label>
                <input type="email" id="email" className="w-full rounded-lg border border-gray-300 p-2" value={email} onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="password" className="text-gray-800">Password</label>
                <input type="password" id="password" className="w-full rounded-lg border border-gray-300 p-2" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button type="submit" className="w-full bg-blue-600 text-white rounded-lg px-4 py-2 mt-2">Login</button>
            </form>
        </div>
    );
}

export default LoginPage;