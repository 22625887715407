import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import MainPage from "./Pages/Main.Page";
import {IForm} from "../../server/src/interfaces";
import FormPage from "./Pages/Form.page";
import Success from "./Pages/Success.Page";
import AdminPage from "./Pages/Admin.page";
import LoginPage from "./Pages/Login.Page";
import {
    PCFACTORY_DEFAULT_FORM_DATA, PCFACTORY_DEFAULT_KARIN_FORM_DATA,
    PORTLAND_DEFAULT_FORM_DATA,
    PORTLAND_DEFAULT_KARIN_FORM_DATA
} from "./Utils/Forms.data";



const FormPropsProxy = () => {
    const {state} = useLocation();
    const {form} = state;

    return <FormPage form={form} />;
}

const SuccessPropsProxy = () => {
    const {state} = useLocation();
    const {password} = state;

    return <Success password={password} />;
}



const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/admin" element={<LoginPage />} />
                <Route path="/admin/participants/" element={<AdminPage />} />

                <Route path="/portland" element={<MainPage forms={[PORTLAND_DEFAULT_FORM_DATA, PORTLAND_DEFAULT_KARIN_FORM_DATA]}/>} />
                <Route path="/pcfactory" element={<MainPage forms={[PCFACTORY_DEFAULT_FORM_DATA, PCFACTORY_DEFAULT_KARIN_FORM_DATA]}/>} />


                <Route path="/:name/form" element={<FormPropsProxy />} />
                <Route path="/:name/success" element={<SuccessPropsProxy />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;